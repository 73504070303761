<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="py-4 my-0 mx-0">
            <div cols="12" class="text-center text-heading-1">
              タグの編集
              <div>
                <v-btn
                  depressed
                  class="close-button"
                  fab
                  color="#E27C7C"
                  width="20"
                  height="20"
                  @click="$emit('close-dialog-claim-category')"
                >
                  <v-icon size="14">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
            <!-- <div cols="12">
              <div class="form-header mb-2">タグカテゴリー</div>
              <div>
                <v-select
                  dense
                  outlined
                  :items="tag_types"
                  placeholder="カテゴリーを選択"
                  v-model="formData.tag_type"
                ></v-select>
              </div>
            </div> -->

          <div class="m-0">
            <div cols="12">
              <div class="form-header mb-2">タグ名</div>
              <div>
                <v-text-field
                  outlined
                  hide-details
                  dense
                  placeholder="タグ名を入力してください。"
                  v-model="formData.name"
                  item-text="name"
                >
                </v-text-field>
              </div>
            </div>
          
            <v-col cols="12">
              <v-row align="center">
                <v-col cols="6">
                  <v-btn color="#AD4545" text @click="deleteData">
                    <v-icon left>$trash</v-icon>
                    削除する
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-col cols="auto">
                    <v-btn
                      color="#4F55A7"
                      class="white--text"
                      width="100"
                      @click="save"
                      >更新する
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Dialog',
  data() {
    return {
      valid: true,
      formData: {
        id: null,
        name: null,
        // tag_type: null
      },
      //  tag_types: [
      //   { text: 'クレーム', value: 1 },
      //   { text: 'アフター', value: 2 }
      // ],
    }
  },
  methods: {
    ...mapMutations(['showModal']),
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('CLAIM_CATEGORY_UPDATE', { ...this.formData })
          .then(
            response => {
              if (response.data.status == 'success') {
                this.getDataFromApi()
                this.$emit('alertMessage', {
                  message: '休暇申請を更新しました。',
                  status: 'success'
                })
              }
            },
            error => {
              throw error
            }
          )
      }
    },
    deleteData() {
      if (this.formData?.id) {
        this.showModal({
          text: '対象項目を削除しますか？ この操作は取り消せません。',
          action: {
            process: {
              color: 'red',
              text: '削除'
            },
            cancel: {
              color: 'secondary',
              text: 'キャンセル'
            }
          },
          func: this.processDelete
        })
      }
    },
    processDelete() {
      this.$store.dispatch('CLAIM_CATEGORY_DELETE', { ...this.formData }).then(
        response => {
          if (response.data.status == 'success') {
            this.getDataFromApi()
            this.$emit('alertMessage', {
              message: '休暇申請を削除しました。',
              status: 'success'
            })
          }
        },
        error => {
          throw error
        }
      )
    },
    async getDataFromApi() {
      this.loading = true
      let params = {}
      params.paginate = 50
      await this.$store.dispatch('CLAIM_CATEGORY_GET_ALL', params)
      this.$emit('close-dialog-claim-category')
      this.loading = false
    }
  },

  watch: {
    '$store.getters.editclaimcategoryData': {
      immediate: true,
      deep: true,
      handler(val) {
        this.formData = { ...val }
      }
    }
  }
}
</script>

<style lang="scss" src="./Model.scss" scoped></style>
