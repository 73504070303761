var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('claimCategory', {
    attrs: {
      "show": _vm.claimCategory
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.claimCategory = $event;
      },
      "close-dialog-claim-category": function closeDialogClaimCategory($event) {
        _vm.claimCategory = false;
      },
      "alertMessage": function alertMessage($event) {
        return _vm.showAlertMessage($event);
      }
    }
  }), _c('v-dialog', {
    ref: "EditModel",
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.EditModel,
      callback: function callback($$v) {
        _vm.EditModel = $$v;
      },
      expression: "EditModel"
    }
  }, [_c('EditModel', {
    on: {
      "close-dialog-claim-category": _vm.closeEditModal,
      "alertMessage": function alertMessage($event) {
        return _vm.showAlertMessage($event);
      }
    }
  })], 1), _c('h3', {
    staticClass: "page-title mb-7 font-weight-bold"
  }, [_vm._v("クレームタグ管理")]), _c('v-row', {
    staticClass: "no-gutters mb-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    staticClass: "btn-new",
    attrs: {
      "height": "28",
      "color": "#CFD3FE",
      "depressed": "",
      "min-width": "80"
    },
    on: {
      "click": _vm.addPhoto
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus")]), _vm._v(" クレームタグの追加 ")], 1)], 1)], 1), _c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.claimCatagories,
      "total-records": 1,
      "number-of-pages": 1,
      "footer": false
    },
    on: {
      "row-clicked": _vm.openEditModel
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          attrs: {
            "color": "primary mr-2",
            "fab": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openEditModel(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }