<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <claimCategory
        :show.sync="claimCategory"
        @close-dialog-claim-category="claimCategory = false"
        @alertMessage="showAlertMessage($event)"
      ></claimCategory>

      <!-- EDIT THE Claim Category DAYS -->
      <v-dialog ref="EditModel" v-model="EditModel" width="500">
        <EditModel
          @close-dialog-claim-category="closeEditModal"
          @alertMessage="showAlertMessage($event)"
        ></EditModel>
      </v-dialog>

      <h3 class="page-title mb-7 font-weight-bold">クレームタグ管理</h3>
      <v-row class="no-gutters mb-6" align="center">
        <!-- <v-col cols="4">
          <v-text-field
            placeholder="キーワード検索"
            v-model="search"
            @input="getDataFromApi"
            dense
            outlined
            hide-details
          >
            <v-btn
              slot="append"
              color="blue"
              small
              @click="getDataFromApi"
              class="white--text"
              elevation="0"
              height="23"
            >
              検索
            </v-btn>
          </v-text-field>
        </v-col> -->
        <v-col class="text-right">
          <v-btn
            height="28"
            class="btn-new"
            color="#CFD3FE"
            depressed
            min-width="80"
            @click="addPhoto"
          >
            <v-icon left> mdi-plus</v-icon>
            クレームタグの追加
          </v-btn>
        </v-col>
      </v-row>
      <Table
        :headers="headers"
        :items="claimCatagories"
        :total-records="1"
        :number-of-pages="1"
        :footer="false"
        @row-clicked="openEditModel"
      >
        <template v-slot:item.id="{ item }">
          <span>{{ item.id }}</span>
        </template>

        <template v-slot:item.name="{ item }">
          <span>{{ item.name }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn color="primary mr-2" fab x-small @click="openEditModel(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </Table>
      <!-- <h1>{{cars}}</h1> -->
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import claimCategory from '@/components/admin/partials/ClaimCategory/CreateModel'
import EditModel from '@/components/admin/partials/ClaimCategory/EditModel'

export default {
  name: 'List',
  components: { Table, claimCategory, EditModel },
  data() {
    return {
      headers: [
        { text: 'ID', align: 'center', value: 'id' },
        {
          text: '名',
          align: 'center',
          value: 'name',
          sortable: false
        },

        {
          text: '',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      claimCategory: false,
      search: '',
      filter: null,
      loading: true,
      itemsPerPage: 25,
      numberOfPages: null,
      totalRecords: 0,
      options: {},
      message: null,
      messageStatus: 'success',
      checkbox: false,
      selected: [],
      actionStatus: null,
      status: [],
      EditModel: false,
      ids: []
      // tagTypeArray: {
      //   1: 'クレーム',
      //   2: 'アフター',
      // },
    }
  },
  computed: {
    claimCatagories() {
      return this.$store.getters.allClaimCategories
    },
    claimPagination() {
      return this.$store.getters.claimPagination
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },

  methods: {
    async getDataFromApi() {
      this.loading = true
      let params = {
        search: this.search,
        paginate: 50,
        page: this.options.page
      }
      await this.$store.dispatch('CLAIM_CATEGORY_GET_ALL', params)
      this.loading = false
    },

    addPhoto() {
      this.claimCategory = true
    },
    showAlertMessage(payload) {
      this.message = payload.message
      this.messageStatus = payload.status
    },
    async openEditModel(item) {
      this.$store.commit('CLAIM_CATEGORY_SET_EDIT_DATA', item)
      this.EditModel = true
    },
    async closeEditModal() {
      this.$store.commit('CLAIM_CATEGORY_SET_EDIT_DATA', {})
      this.EditModel = false
    }
  },
  created() {
    this.getDataFromApi()
  }
}
</script>

<style src="./List.scss" lang="scss" scoped></style>