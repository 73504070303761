<template>
  <v-dialog v-model="dialog" ref="claimCategory" width="500">
    <v-card>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="py-4 my-0 mx-0">
              <div cols="12" class="text-center text-heading-1">
                タグの追加
                <div>
                  <v-btn
                    depressed
                    class="close-button"
                    fab
                    color="#E27C7C"
                    width="20"
                    height="20"
                    @click="closeModel"
                  >
                    <v-icon size="14">mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- <div cols="12">
              <div class="form-header mb-2">タグカテゴリー</div>
              <div>
                <v-select
                  dense
                  outlined
                  :items="tag_types"
                  placeholder="カテゴリーを選択"
                  v-model="formData.tag_type"
                ></v-select>
              </div>
            </div> -->

            <div class="m-0">
              <div cols="12">
                <div class="form-header mb-2">タグ名</div>
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    placeholder="タグ名を入力"
                    v-model="formData.name"
                  >
                  </v-text-field>
                </div>
              </div>

              <v-col cols="12" class="error--text pa-0" v-if="alert">
                {{ alertMessage }}
              </v-col>

              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="6" class="offset-6 text-right">
                    <v-col cols="auto">
                      <v-btn
                        color="#4F55A7"
                        class="white--text"
                        width="100"
                        @click="save"
                        :loading="loading"
                        >登 録
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from '@/plugins/dayjs'
export default {
  name: 'Dialog',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: true,

      formData: {
        // tag_type: null,
        name: null
      },
      // tag_types: [
      //   { text: 'クレーム', value: 1 },
      //   { text: 'アフター', value: 2 }
      // ],

      status: [],
      alert: false,
      alertMessage: '',
      loading: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.show
      },
      set(val) {
        return this.$emit('update:show', val)
      }
    }
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.alert = false
      }
    }
  },
  methods: {
    formatDate(e) {
      return dayjs(e).format('D')
    },
    save() {
      this.alert = false
      this.loading = true
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('CLAIM_CATEGORY_CREATE', this.formData)
          .then(
            response => {
              if (response.data.status == 'success') {
                this.clearModelData()
                this.getDataFromApi()
                this.reset()
                this.$emit('close-dialog-claim-category')
                this.$emit('alertMessage', {
                  message: '休暇申請を作成しました',
                  status: 'success'
                })
              }
              this.loading = false
            },
            error => {
              this.alert = true
              this.alertMessage = error?.data?.message
              this.loading = false
              throw error
            }
          )
          .finally(() => {
            this.loading = false
          })
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    closeModel() {
      this.reset()
      this.$emit('close-dialog-claim-category')
    },

    clearModelData() {
      this.formData.name = null
    },
    async getDataFromApi() {
      this.loading = true
      await this.$store.dispatch('CLAIM_CATEGORY_GET_ALL')
      this.loading = false
    }
  }
}
</script>

<style lang="scss" src="./Model.scss" scoped></style>
